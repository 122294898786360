<template>
  <base-loader v-if="loading"></base-loader>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
          {{ title }}
        </h3>
        <div>
          <button class="btn btn-success btn-sm" @click="smsModal = true">
            <i class="fas fa-plus"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="sms"
      >
        <!-- Table Head -->
        <template v-slot:columns>
          <th>#</th>
          <th>{{ $t("tables.phone") }}</th>
          <th>{{ $t("tables.message") }}</th>
          <th>{{ $t("tables.order") }}</th>
        </template>

        <!-- Table Body -->
        <template v-slot:default="row">
          <td class="budget">
            {{ row.item.id }}
          </td>
          <td class="budget">
            {{ row.item.phone_number }}
          </td>
          <td class="budget">
            {{ row.item.message }}
          </td>
          <td class="budget">
            <router-link
              v-if="row.item.order_id"
              :to="`/orders/${row.item.order_id}`"
              class="btn btn-primary btn-sm"
              ><i class="fas fa-eye"></i
            ></router-link>
          </td>
        </template>
      </base-table>
    </div>
    <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <!-- Pagination Component -->
      <base-pagination
        :page="page"
        :total_pages="total_pages"
        v-on:pagination-num="page = $event"
      />
    </div>
  </div>
  <!-- Sms Modal -->
  <div
    v-if="smsModal"
    class="modal fade show d-block"
    id="smsModal"
    tabindex="-1"
    aria-labelledby="smsModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="smsModalLabel">
            {{ $t("form.sms_form") }}
          </h5>
          <button type="button" class="btn-secondary" @click="smsModal = false">
            x
          </button>
        </div>
        <div class="modal-body">
          <form>
            <!-- Errors Handling -->
            <div class="row bg-danger py-3 mb-3" v-if="validationErrors.length">
              <div
                class="col-md-6"
                v-for="(error, index) in validationErrors"
                :key="index"
              >
                <span class="text-white mr-2 error-handling">{{ error }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 mb-3">
                <label class="form-control-label">{{ $t("form.phone") }}</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="newData.phone"
                />
              </div>
              <div class="col-lg-12 mb-3">
                <label class="form-control-label">{{
                  $t("form.message")
                }}</label>
                <textarea
                  type="text"
                  class="form-control"
                  v-model="newData.msg"
                ></textarea>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            @click="smsModal = false"
          >
            {{ $t("form.close") }}
          </button>
          <button type="button" class="btn btn-success" @click="sendSms()">
            {{ $t("form.save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "sms-table",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      renderComponent: false,
      loading: false,
      validationErrors: [],
      smsModal: false,
      newData: {},
      sms: "",
      page: 1,
      total_pages: "",
    };
  },
  created() {
    this.getSms("admin/administration/sms/index?page=1");
  },
  watch: {
    page: function () {
      this.getSms("admin/administration/sms/index?page=" + this.page);
    },
  },
  methods: {
    getSms: function ($pagination) {
      this.loading = true;
      axios
        .get($pagination)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            this.sms = response.data.data;
            this.page = response.data.meta.current_page;
            this.total_pages = response.data.meta.last_page;
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    validation: function () {
      this.validationErrors = [];
      if (this.newData.phone && this.newData.msg) {
        return true;
      } else {
        if (!this.newData.phone) {
          this.validationErrors.push(`${this.$t("validation.phone required")}`);
        }
        if (!this.newData.msg) {
          this.validationErrors.push(`${this.$t("validation.msg required")}`);
        }
        return false;
      }
    },
    // Create Or Update Sms
    sendSms: function () {
      if (this.validation() == false) {
        this.$swal({
          position: "center",
          icon: "error",
          title: `${this.$t("validation.faild")}`,
          showConfirmButton: false,
          timer: 1200,
        });
        return false;
      }
      this.loading = true;
      axios
        .post("admin/administration/sms/send", this.newData)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            if (response.data.status == 0) {
              this.$swal({
                position: "center",
                icon: "error",
                title: response.data.sms,
                showConfirmButton: false,
              });
            } else {
              this.smsModal = false;
              this.sms.unshift({
                message: this.newData.msg,
                phone_number: this.newData.phone,
              });
              this.newData = {};
              this.$swal({
                position: "center",
                icon: "success",
                title: `${this.$t("validation.success")}`,
                text: response.data.sms,
                showConfirmButton: false,
                timer: 1200,
              });
            }
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
