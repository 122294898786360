<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <sms-table type="dark" :title="$t('sidebar.sms')"></sms-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SmsTable from "../Tables/Sms/SmsTable.vue";
export default {
  name: "SMS",
  components: {
    SmsTable,
  },
};
</script>
